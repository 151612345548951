.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  #map {
    height: calc(100vh - 72px);
  }
}

.leaflet-control-container {
  /*.leaflet-top .leaflet-control {
    margin-top: 16px !important;
  }
  .leaflet-right .leaflet-control {
    margin-right: 16px !important;;
  }*/
  .leaflet-control-zoom {
    margin: 16px 16px 0 0;
    border: solid 1px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 4px 6px -2px rgba(64, 64, 65, 0.2);
    overflow: hidden;
    > a {
      font-size: 16px;
      width: 32px;
      height: 31px;
      line-height: 31px;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
      &.leaflet-control-zoom-in {
        border-bottom-color: var(--quarternary-color);
      }
    }
  }
}

.place-icon {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px rgba(64, 64, 65, 0.2);
  img {
    width: 16px;
    height: 16px;
  }
  &:after {
    content: '';
    display: inline-flex;
    width: 4px;
    height: 4px;
    background-color: var(--white-color);
    position: absolute;
    left: 50%;
    bottom: -8px;
    margin-left: -1.5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(64, 64, 65, 0.2);
  }
  &:before {
    content: '';
    height: 2px;
    width: 2px;
    position: absolute;
    background-color: var(--white-color);
    bottom: -2px;
    left: 50%;
    margin-left: -2px;
    border-top: var(--white-color) solid 2px;
    border-left: var(--white-color) solid 2px;
    transform: rotate(225deg);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(64, 64, 65, 0.2);
  }
}

.score {
  position: relative;
  display: inline-flex;
  .text {
    color: #fff;
    font-family: var(--font-montserrat);
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.18px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    margin-top: -2px;
  }
  .score-bg {
    svg {
      width: 32px;
      height: auto;
    }
  }
  &.small {
    .text {
      font-size: 10px;
    }
    svg {
      width: 22px;
      height: auto;
    }
  }
  &.large {
    .text {
      font-size: 22px;
      letter-spacing: 0.3px;
    }
    svg {
      width: 50px;
      height: auto;
    }
  }
}


.leaflet-container {
  .triply-control-back {
    background: var(--white-color);
    margin: 10px 16px 0 0;
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    box-shadow: 0 4px 6px -2px rgb(64 64 65 / 20%);
    overflow: hidden;
    color: #000;
    font-size: 14px;
    width: 32px;
    height: 31px;
    line-height: 31px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f4f4f4;
    }
  }
}
