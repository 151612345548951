form {
  .form-group {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    label {
      display: block;
      color: var(--secondary-color);
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.13px;
      text-transform: uppercase;
      padding-bottom: 7px;
    }
    input {
      padding: 14px 16px 13px;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid #e6e6e6;
      outline: none;
      &:focus {
        border-color: var(--main-color);
        -webkit-box-shadow: 0px 0px 0px 1px var(--main-color);
        -moz-box-shadow: 0px 0px 0px 1px var(--main-color);
        box-shadow: 0px 0px 0px 1px var(--main-color);
      }
      &.ng-invalid.ng-touched {
        border-color: var(--rating-low-color);
        -webkit-box-shadow: 0px 0px 0px 1px var(--rating-low-color);
        -moz-box-shadow: 0px 0px 0px 1px var(--rating-low-color);
        box-shadow: 0px 0px 0px 1px var(--rating-low-color);
      }
      &::placeholder {
        color: var(--secondary-color);
      }
    }
    .invalid-feedback {
      margin-top: 8px;
      color: var(--rating-low-color);
      font-size: 10px;
      letter-spacing: 0.13px;
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        background: url('../images/icons/warning.svg') no-repeat;
        width: 16px;
        height: 16px;
        display: inline-flex;
        margin-right: 8px;
      }
    }
  }
}

.toggle-button {
  padding: 16px 16px 11px;
  color: var(--secondary-color);
  font-weight: 500;
  line-height: 1.62;
  display: block;
  max-width: 100%;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  .icon {
    font-size: 16px;
  }
  .mode {
    color: inherit;
    line-height: 1;
    margin-top: 12px;
  }
  &.active {
    color: var(--white-color);
    background-color: var(--main-color);
    border-color: var(--main-color);
  }
}


.btn {
  display: inline-block;
  color: var(--main-color);
  font-family: var(--font-montserrat);
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  .icon {
    margin-left: 5px;
  }
  &.button {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    background-color: var(--main-color);
    text-transform: uppercase;
    padding: 13px 20px 13px 23px;
    border: 2px solid var(--main-color);
    outline: none;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    &:hover {
      background-color: transparent;
      color: var(--main-color);
    }
  }
  [class^="triply-"] {
    padding-left: 8px;
    font-size: 12px;
  }
}
