@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
  url('../fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
  url('../fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'triply';
  src:  url('../fonts/triply/triply.eot?xjs943');
  src:  url('../fonts/triply/triply.eot?xjs943#iefix') format('embedded-opentype'),
  url('../fonts/triply/triply.ttf?xjs943') format('truetype'),
  url('../fonts/triply/triply.woff?xjs943') format('woff'),
  url('../fonts/triply/triply.svg?xjs943#triply') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="triply-"], [class*=" triply-"] {
  font-family: 'triply' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.triply-back:before {
  content: "\e917";
}
.triply-entertainment:before {
  content: "\e914";
}
.triply-sports:before {
  content: "\e915";
}
.triply-common_services:before {
  content: "\e916";
}
.triply-settings:before {
  content: "\e900";
}
.triply-check-circle:before {
  content: "\e901";
}
.triply-arrow-right:before {
  content: "\e902";
}
.triply-call:before {
  content: "\e903";
}
.triply-close:before {
  content: "\e904";
}
.triply-day:before {
  content: "\e905";
}
.triply-healthcare:before {
  content: "\e906";
}
.triply-education:before {
  content: "\e907";
}
.triply-library:before {
  content: "\e908";
}
.triply-map:before {
  content: "\e909";
}
.triply-menu:before {
  content: "\e90a";
}
.triply-minus:before {
  content: "\e90b";
}
.triply-night:before {
  content: "\e90c";
}
.triply-pin:before {
  content: "\e90d";
}
.triply-plus:before {
  content: "\e90e";
}
.triply-schools:before {
  content: "\e90f";
}
.triply-search:before {
  content: "\e910";
}
.triply-store:before {
  content: "\e911";
}
.triply-tip:before {
  content: "\e912";
}
.triply-warning:before {
  content: "\e913";
}
