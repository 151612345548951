:root {
  --font-montserrat: 'Montserrat', sans-serif;
  --font-raleway: 'Raleway', sans-serif;
  --body-font-size: 13px;
  --primary-color: #404041;
  --secondary-color: #9d9d9c;
  --white-color: #fff;
  --tertiary-color: #F0F0F0;
  --quarternary-color: #F6F6F6;
  --main-color: #1fac78;
  --main-dark-color: #1F996C;
  --main-light-color: #EBFFEE;
  --rating-vlow-color: #E65639;
  --rating-low-color: #F2A230;
  --rating-medium-color: #F2C230;
  --rating-vgood-color: #29CC7A;
  --rating-good-color: #82D957;
  --score-color: #F9BE00;
}
