body,
input {
  margin: 0;
  font-family: var(--font-montserrat);
  font-size: var(--body-font-size);
  line-height: 21px;
  color: var(--primary-color);
}

h1, h2, h3, h4 {
  color: var(--primary-color);
  margin: 0;
}
h1 {
  font-family: var(--font-raleway);
  font-size: 30px;
  line-height: 2.154;
  letter-spacing: 0.38px;
}
h2 {
  font-family: var(--font-raleway);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.313;
  letter-spacing: 0.25px;
}
h3 {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.15px;
  margin-bottom: 0;
  &.heading {
    margin-bottom: 12px;
    color: var(--secondary-color);
    text-transform: uppercase;
    line-height: 1;
    font-weight: 600;
  }
}

a {
  text-decoration: none;
}

hr {
  border: solid 1px #e6e6e6;
  width: 100%;
}

.secondary-color {
  color: var(--secondary-color);
}

.pac-container {
  z-index: 9999 !important;
  .pac-item {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 12px;
  }
}
